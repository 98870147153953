import Registrations from 'modules/admin/components/courses/Registrations';
import AdminGuard from 'modules/auth/components/AdminGuard';

const IndexPage = () => {
  return (
    <AdminGuard>
      <Registrations></Registrations>
    </AdminGuard>
  );
};

export default IndexPage;
